



















import { Component, Mixins } from "vue-property-decorator";
import HelperDinamicallyForm from "@/components/DinamicallyForm/HelperDinamicallyForm";
import ambitoModule from "@/store/modules/ambito-module";
import { ambito } from "@/shared/dtos/ambito";

@Component({
  components: {},
})
export default class subambitosFormulario extends Mixins(
  HelperDinamicallyForm
) {
  public item_pantalla: ambito = new ambito();

  public async OnCreated() {
    if (this.getparamId().HayParametro) {
      await ambitoModule.getambito(this.getparamId().Parametro);
      this.item_pantalla = ambitoModule.ambito;
    }
    //Para la clonacion automatica
    this.BasicElement = this.item_pantalla;
    ambitoModule.getambitos();
  }

  public CreateFields() {
    //Empezamos a crear una pantalla
    //configuracion de la clonacion automatica

    this.AddFormItemDataDefaultString(
      this.item_pantalla.nombre,
      "Nombre",
      "nombre",
      60
    )
      .isRequired()
      .Dimesiones(12, 6, 6);

    this.AddFormItemDataDefaultString(
      this.item_pantalla.nomenclatura,
      "Nomenclatura",
      "nomenclatura",
      60
    )
      .isRequired()
      .Dimesiones(12, 6, 6);

    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.id_ambito,
      "Subámbito",
      "id_ambito"
    )
      .isRequired()
      .CreateComboSingle(
        ambitoModule.ambitos,
        "nombre",
        "id"
      )
      .Dimesiones(12, 6, 6);
  }

  public Insertar(object: ambito) {
    ambitoModule.guardarambito(object).then(() => {
      this.AllSaveOk();
    });
  }

  public Actualizar(object: ambito) {
    ambitoModule.modificarambito(object).then(() => {
      this.AllSaveOk();
    });
  }
}
